import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType } from '../models/notification.model';

@Pipe({
  name: 'notificationtype',
})
export class NotificationTypePipe implements PipeTransform {
  transform(value: NotificationType): string {
    if (typeof value === 'string') {
      return `Administration.Notifications.NotificationTypes.${value}`;
    }
  }
}
