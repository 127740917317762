import { Pipe, PipeTransform } from '@angular/core';
import { TransactionBatchUpdateStatus } from '../services/batch/batch.types';

@Pipe({
  name: 'transactionBatchUpdateStatus',
})
export class TransactionBatchUpdateStatusPipe implements PipeTransform {
  transform(value: TransactionBatchUpdateStatus): string {
    switch (value) {
      case TransactionBatchUpdateStatus.UNTOUCHED:
        return 'Base.Error';
      case TransactionBatchUpdateStatus.UPDATED:
        return 'Base.Fixed';
      case TransactionBatchUpdateStatus.DELETED:
        return 'Base.Eliminated';
    }
  }
}
