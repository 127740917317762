import { Pipe, PipeTransform } from '@angular/core';
import { OperationType } from '../models/operation.model';

@Pipe({ name: 'operationtype' })
export class OperationTypePipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      return `OperationTypes.${value}`;
    }

    return `OperationTypes.${OperationType[value]}`;
  }
}
