import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-walktrough',
  templateUrl: './walktrough.component.html',
  styleUrls: ['./walktrough.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WalktroughComponent implements OnInit {
  @Input() showModal = false;
  @Output() completed = new EventEmitter<string>();

  @ViewChild('plansSwiper', { static: false }) plansSwiper: SwiperComponent;
  steps = [];
  public swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: true,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      1600: {
        slidesPerView: 1,
      },
      1366: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 1,
      },
    },
  };

  ngOnInit(): void {
    this.initializeSteps();
  }

  public close(): void {
    this.completed.emit('WELCOME_PAGE');
  }

  private initializeSteps(): void {
    this.steps = [
      {
        image: 'walktrough/onboarding-1.png',
        title: 'Walktrough.Steps.Welcome.Title',
        description: 'Walktrough.Steps.Welcome.Description',
      },
      {
        image: 'walktrough/onboarding-2.png',
        title: 'Walktrough.Steps.Security.Title',
        description: 'Walktrough.Steps.Security.Description',
      },
      {
        image: 'walktrough/onboarding-3.png',
        title: 'Walktrough.Steps.Roles.Title',
        description: 'Walktrough.Steps.Roles.Description',
      },
      {
        image: 'walktrough/onboarding-4.png',
        title: 'Walktrough.Steps.Transactions.Title',

        description: 'Walktrough.Steps.Transactions.Description',
      },
      {
        image: 'walktrough/onboarding-5.png',
        title: 'Walktrough.Steps.News.Title',
        description: 'Walktrough.Steps.News.Description',
      },
    ];
  }
}
