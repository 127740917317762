export interface Bank {
  id: string;
  name: string;
  nit: string;
  number: number;
  digit: number;
  code?: number;
  availableAccountTypes?: string[];
}

export class Iris {
  public static bankName = 'Iris';
}
