import { IMapRoleName } from '../services/roles/role.types';

export enum Status {
  New = 'NEW',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blocked = 'BLOCKED',
  ExpiredInvitation = 'EXPIRED_INVITATION',
}

export enum SystemRole {
  Master = 'Master',
  SuperAdmin = 'SuperAdmin',
  Authorizer = 'Authorizer',
  Operator = 'Operator',
  ReadOnly = 'ReadOnly',
}

export enum UserDocumentType {
  CedulaCiudadania = 'ID',
  CedulaExtranjeria = 'CE',
  NIT = 'NIT',
  TarjetaIdentidad = 'IDENTITY_CARD',
  RegistroCivil = 'CIVIL_REGISTRATION',
  Passport = 'PASSPORT',
  ForeignId = 'FOREIGN_ID',
  DiplomaticId = 'DIPLOMATIC_ID',
  Fideicomiso = 'FIDEICOMISO',
  PermisoProteccionTemporal = 'PPT',
}

export enum Indicative {
  COL = '+57',
}

export enum IdentityVerificationStatus {
  Undefined = 'UNDEFINED',
  Required = 'REQUIRED',
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED',
  InExecution = 'IN_EXECUTION',
}

export enum TitleType {
  UNDEFINED = 'UNDEFINED',
  LEGAL_REPRESENTATIVE = 'LEGAL_REPRESENTATIVE',
}

export interface User extends IMapRoleName {
  companyDocument: string;
  area?: string;
  teamName?: any;
  team?: string;
  userId: string;
  status: Status;
  userStatus?: boolean;
  lastAccess: string;
  lastAccessLocation: string;
  name: string;
  position?: string;
  lastName: string;
  fullName: string;
  depositAccounts: string[];
  userDocumentId: string;
  userDocumentType: UserDocumentType;
  username: string | null;
  email: string;
  phoneNumberIndicative?: Indicative;
  phoneNumber: string;
  avatar?: string;
  selected?: boolean;
  franchise?: string;
  identityVerificationStatus?: IdentityVerificationStatus;
  title: TitleType;
}


export interface CardEprepaid {
  userId: string;
  franchise?: string;
  pam?: string;
}
export interface UserList extends IMapRoleName {
  userId: string;
  name: string;
  lastName:string;
  email: string;
  roleId: string;
  phoneNumber: string;
  area: string | null;
  status: string;
}
