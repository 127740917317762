import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from '../services/transaction/transaction.types';

@Pipe({
  name: 'transactionstatus',
})
export class TransactionStatusPipe implements PipeTransform {
  transform(value: TransactionStatus): string {
    return `Transactions.ScheduledTransactions.Status.${TransactionStatus[value]}`;
  }
}
