import { Pipe, PipeTransform } from '@angular/core';
import { SchedulePeriodicity } from '../services/transaction/transaction.types';

@Pipe({ name: 'periodicity' })
export class PeriodicityPipe implements PipeTransform {
  transform(value: SchedulePeriodicity | string): string {
    if (typeof value === 'string' && isNaN(parseInt(value))) {
      return `Periodicities.${value}`;
    }
    return `Periodicities.${SchedulePeriodicity[value]}`;
  }
}
