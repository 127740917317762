<div blockCopyAndPaste class="banca-wrapper">
  <router-outlet></router-outlet>
  <ng-http-loader [entryComponent]="spinnerComponent" [filteredUrlPatterns]="filteredUrlsForLoader"></ng-http-loader>
  <iris-network-issues></iris-network-issues>
  <app-walktrough
    *ngIf="showWelcome"
    [showModal]="showWelcome"
    (completed)="updateWalktrough($event)"
  ></app-walktrough>
</div>
