import { Pipe, PipeTransform } from '@angular/core';
import { LogComponent } from '../models/log.model';

@Pipe({ name: 'logcomponent' })
export class LogComponentPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      return `LogComponents.${value}`;
    }

    return `LogComponents.${LogComponent[value]}`;
  }
}
