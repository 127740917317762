export enum DepositAccountType {
  UNDEFINED = 'UNDEFINED',
  LOAN = 'LOAN',
  DEPOSIT = 'DEPOSIT',
  CHECKING = 'CHECKING',
  ELECTRONIC = 'ELECTRONIC',
}

export interface Balances {
  availableBalance: number;
  totalBalance: number;
  truncatedTotalBalance?: number;
  blockedBalance?: number;
}

export interface Credit {
  loanId: string;
  loanBalance: string;
}

export interface DepositAccount {
  id: string;
  encodedKey: string;
  name: string;
  mambuName: string;
  credits?: Credit[];
  isControlAccount: boolean;
  balances: Balances;
  product?: DepositAccountProductType;
  plan?: DepositAccountPlan;
}

export enum DepositAccountProductType {
  SAVINGS_ACCOUNT = 'SAVINGS_ACCOUNT',
  CONTROL_ACCOUNT = 'CONTROL_ACCOUNT',
}

export interface DepositAccountsSummary {
  totalBalances: Balances;
  depositAccounts: DepositAccount[];
}

export interface DepositAccountHolder {
  name: string;
  documentType: string;
  documentId: string;
}

export interface DepositAccountPlan {
  id: string;
  endDate: string;
  period: string;
  currentInterbankTransactions: number;
}

export interface Depositaccounts {
  generalBudget: GeneralBudgetType;
  totalTransactionalBalance: number;
  allocatedBudget: AllocatedBudgetType;
  planId: string;
  name: string;
}

export interface GeneralBudgetType {
  monthlyAllocatedBudget: number;
  totalBalance: number;
  unallocatedBudget: number
}

export interface AllocatedBudgetType {
  monthlyAvailablebudget: number;
  monthlySpendingBudget: number;
  totalBalance: number
}
export interface CorporationResponseViewModel {
  transactionalAccountId: number,
  transactionalAccountEncodedKey: string
}
