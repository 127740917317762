import { TransactionFilters } from '../transaction/transaction.types';

export enum ExportType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  XLS = 'WORKSHEET'
}

export interface ExportTransactionFilters extends TransactionFilters {
  uuid?: string;
}

export interface ExportFileViewModel {
  id: string;
}
