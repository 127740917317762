import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TransferType } from '../services/transaction/transaction.types';
import { AccountService } from '@iris/iris-authentication';

@Directive({
  selector: '[validtransferamount]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: TransferAmountDirective,
      multi: true,
    },
  ],
})
export class TransferAmountDirective implements AsyncValidator {
  @Input() maxAmount: number;
  @Input() transferType: TransferType;
  @Input() disablePermissionValidation: boolean = false;
  MODULE_TRANSACTIONS_KEY = 'TRANSACTIONS';

  constructor(
    private accountService: AccountService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  validate(
    control: AbstractControl,
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    if (!control) return;
    if (control.value < 1) {
      return of({ invalid: true });
    }
    if (control.value > this.maxAmount) {
      return of({ surpassesBalance: true });
    }
    if (this.disablePermissionValidation) return of(null);
    return timer(500).pipe(
      switchMap(() => this.accountService.role()),
      catchError(e => {
        throw e;
      }),
      map(roles => {
        let permissionType;
        switch (this.transferType) {
          case TransferType.OWN_ACCOUNT:
            permissionType = 'TRANSACTIONS_OWN_ACCOUNTS';
            break;
          case TransferType.SAME_BANK:
            permissionType = 'TRANSACTIONS_ACCOUNTS_SAME_BANK';
            break;
          case TransferType.OTHER_BANK:
            permissionType = 'TRANSACTIONS_ACCOUNTS_OTHER_BANK';
            break;
        }
        const permission = roles.permissions.find(
          p =>
            p.module === this.MODULE_TRANSACTIONS_KEY &&
            p.permission === permissionType,
        );
        this.changeDetector.markForCheck();
        if (
          permission?.options.amount !== null &&
          permission?.options.amount < control.value
        ) {
          return { surpassesRoleLimit: true };
        } else {
          return null;
        }
      }),
    );
  }
}
