
import { Injectable } from '@angular/core';

@Injectable()
export class FileDownloaderService {
  public downloadfile(blob: Blob, fileName: string): void {
    let navigator: any = window.navigator;
    // IE
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      let l = <any>document.createElement('a');

      l.href = window.URL.createObjectURL(blob);
      l.download = fileName;
      document.body.appendChild(l);
      l.click();
      window.setTimeout(() => {
        document.body.removeChild(l);
        window.URL.revokeObjectURL(l.href);
      }, 0);
    }
  }
}
