export interface IMapRoleName {
  roleId: string;
  roleName: string;
}

export enum RoleModules {
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  SUMMARY = 'SUMMARY',
  TRANSACTIONS = 'TRANSACTIONS',
  PAYMENTS = 'PAYMENTS',
  ADMINISTRATION = 'ADMINISTRATION',
  PSE = 'PSE',
  IRISCARD = 'IRISCARD',
  IRISPAY = 'IRISPAY'
}

export enum RolePermissions {
  USERS = 'USERS',
  ROLE_AND_LIMITS = 'ROLE_AND_LIMITS',
  USER_LOGS = 'USER_LOGS',
  COMPLETE_SUMMARY = 'COMPLETE_SUMMARY',
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
  OPERATION_DETAILS = 'OPERATION_DETAILS',
  TRANSACTIONS_OWN_ACCOUNTS = 'TRANSACTIONS_OWN_ACCOUNTS',
  TRANSACTIONS_ACCOUNTS_SAME_BANK = 'TRANSACTIONS_ACCOUNTS_SAME_BANK',
  TRANSACTIONS_ACCOUNTS_OTHER_BANK = 'TRANSACTIONS_ACCOUNTS_OTHER_BANK',
  TRANSACTIONS_BATCHES = 'TRANSACTIONS_BATCHES',
  TRANSACTIONS_INSCRIBED_ACCOUNTS = 'TRANSACTIONS_INSCRIBED_ACCOUNTS',
  PAYMENTS_PAYROLL_MANUAL = 'PAYMENTS_PAYROLL_MANUAL',
  PAYMENTS_PROVIDERS_MANUAL = 'PAYMENTS_PROVIDERS_MANUAL',
  PAYMENTS_PAYROLL_BATCHES = 'PAYMENTS_PAYROLL_BATCHES',
  PAYMENTS_PROVIDERS_BATCHES = 'PAYMENTS_PROVIDERS_BATCHES',
  RECEIPTS_DOWNLOADS_SHARE_FILES = 'RECEIPTS_DOWNLOADS_SHARE_FILES',
  IDENTIFICATION_TAGS = 'IDENTIFICATION_TAGS',
  IDENTIFICATION_ACCOUNTS = 'IDENTIFICATION_ACCOUNTS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  PSE = 'PSE',
  PLANS = 'PLANS',
  EPREPAID_BUDGET_MANAGEMENT = 'EPREPAID_BUDGET_MANAGEMENT',
  AREAS_AND_TEAMS = 'AREAS_AND_TEAMS',
  AREA_LIMIT_SETTINGS = 'AREA_LIMIT_SETTINGS',
  CARDS_MANAGEMENT = 'CARDS_MANAGEMENT',
  IMMEDIATE_BLOCKAGES = 'IMMEDIATE_BLOCKAGES',
  CONFIGURATION_MANAGEMENT = 'CONFIGURATION_MANAGEMENT',
  SMARTLINKS_MANAGEMENT = 'SMARTLINKS_MANAGEMENT',
  SMARTLINKS_DISABLE = 'SMARTLINKS_DISABLE',
  HISTORY_EXPORT = 'HISTORY_EXPORT',
}
