import { Pipe, PipeTransform } from "@angular/core";
import { AddOns } from "../services/addOns/addOns.types";

@Pipe({
  name: 'complement'
})
export class ComplementPipe implements PipeTransform {
  transform(name: AddOns | string): string {
    switch (name) {
      case AddOns.IrisCard:
        return "IrisCard";
      case AddOns.IrisPay:
        return "IrisPay";
      default:
        return null;
    }
  }
}
