import { Pipe, PipeTransform } from "@angular/core";
import { CardPaymentChannels } from "../models";

@Pipe({
  name: 'cardpaymentchannel'
})
export class CardPaymentChannelPipe implements PipeTransform {
  transform(value: CardPaymentChannels | string): any {
    return "CardPaymentChannels.FALLBACK"
  }
}