import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models';

@Pipe({
  name: 'username',
})
export class UserNamePipe implements PipeTransform {
  transform(value: User, format: string) {
    let name = '';
    switch (format) {
      case 'fullname':
        name = value.fullName;
        break;
      case 'firstname':
        name = value.name;
        break;
      case 'fullnameshort':
        name = `${value.name?.split(' ', 1)} ${
          value.lastName ? value.lastName?.split(' ', 1) : ''
        }`;
        break;
    }

    return name;
  }
}
