import { Pipe, PipeTransform } from '@angular/core';
import { MambuTransactionStatus } from '../models';

@Pipe({ name: 'transactionmambustatus' })
export class TransactionMambuStatusPipe implements PipeTransform {
  transform(value: MambuTransactionStatus): string {
    if (typeof value === 'string') {
      return `TransactionStatus.${value}`;
    }

    return `TransactionStatus.${MambuTransactionStatus[value]}`;
  }
}
