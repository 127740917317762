import { APP_BASE_HREF, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpBackend
} from '@angular/common/http';
import es from '@angular/common/locales/es';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthGuard, IrisAuthenticationConfiguration, IrisAuthenticationConstants, ResponseInterceptor, TokenInterceptor
} from '@iris/iris-authentication';
import {
  assetUrl,
  IrisApplication,
  IrisBaseConfiguration,
  IrisBaseConstants
} from '@iris/iris-base';
import { FraudMonitoringInterceptor, IrisOtpConfiguration, IrisOtpConstants } from '@iris/iris-otp';
import { IrisComponentsConstants } from '@iris/iris-components';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { es_ES, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ServicesInjection } from 'src/lib/services/dependencyInjection';
import { HandleNoInternetService } from 'src/lib/services/network/handleNoInternet.service';
import { HandleDeniedOtp } from 'src/lib/services/otp/otpDeniedHandler.service';
import { HandleSessionControlService } from 'src/lib/services/session/handleSessionControl.service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseModule } from './base/base.module';
import { WrapperComponent } from './base/wrapper/wrapper.component';
import { RefreshComponent } from './refresh/refresh.component';
import { WalktroughModule } from './small-features/walktrough/walktrough.module';
import { EmptyComponent } from './empty/empty.component';
import { SecurityInterceptor } from 'src/lib/interceptors/security.interceptor';

// Add colombia to Angular format
registerLocaleData(es);

function buildTranslationResources() {
  const modules = [
    'accountdetails',
    'accountmanagement',
    'administration',
    'dashboard',
    'documents',
    'forgotpassword',
    'inscribeaccount',
    'onboarding',
    'operationdetail',
    'operationhistory',
    'payments',
    'pendingtransactions',
    'transactions',
    'transfer',
    'walktrough',
  ];

  const resources = [
    { prefix: assetUrl('/i18n/'), suffix: '.json' },
    { prefix: assetUrl('/i18n/base.'), suffix: '.json' },
    { prefix: assetUrl('/i18n/otp.'), suffix: '.json' },
    { prefix: assetUrl('/i18n/components.'), suffix: '.json' },
    { prefix: assetUrl('/i18n/enums/'), suffix: '.json' },
    { prefix: assetUrl('/i18n/errors/'), suffix: '.json' },
  ];

  modules.forEach(module => {
    resources.push({
      prefix: assetUrl(`/i18n/modules/${module}.`),
      suffix: '.json',
    });
  });

  return resources;
}

// Load translations before rendering application
export function AppInitializerFactory(
  translate: TranslateService,
  injector: Injector,
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null),
      );
      locationInitialized.then(() => {
        const langToSet = 'es';
        translate.setDefaultLang(langToSet);
        translate.use(langToSet).subscribe(() => resolve(null));
      });
    });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, buildTranslationResources());
}

export function IrisBaseConfigurationFactory(
  handler: HandleNoInternetService,
): IrisBaseConfiguration {
  return {
    application: IrisApplication.BANCA_DIGITAL,
    handleNetworkIssues: handler,
  } as IrisBaseConfiguration;
}

export function IrisOtpConfigurationFactory(handler: HandleDeniedOtp
  ): IrisOtpConfiguration {
    return {
      otpApi: environment.otpApi,
      handleDeniedOtp: handler
    }
}

export function IrisAuthenticationConfigurationFactory(
  handler: HandleSessionControlService,
): IrisAuthenticationConfiguration {
  return {
    accountApi: environment.accountApi,
    websocketApi: environment.websocketApi,
    holidaysApi: environment.holidaysApi,
    loginApi: environment.loginApi,
    staticApiKey: environment.staticApiKey,
    sessionConfiguration: environment.sessionConfiguration,
    handleSessionControlService: handler,
  } as IrisAuthenticationConfiguration;
}

@NgModule({
  declarations: [AppComponent, RefreshComponent, WrapperComponent, EmptyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BaseModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    WalktroughModule
  ],
  exports: [NzDatePickerModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: NZ_I18N, useValue: es_ES },
    { provide: APP_BASE_HREF, useValue: '' },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FraudMonitoringInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'COP' },
    AuthGuard,
    {
      provide: IrisBaseConstants.CONFIGURATION,
      useFactory: IrisBaseConfigurationFactory,
      deps: [HandleNoInternetService],
    },
    {
      provide: IrisAuthenticationConstants.CONFIGURATION,
      useFactory: IrisAuthenticationConfigurationFactory,
      deps: [HandleSessionControlService],
    },
    ServicesInjection,
    {
      provide: IrisOtpConstants.CONFIGURATION,
      useFactory: IrisOtpConfigurationFactory,
      deps: [HandleDeniedOtp],
    },
    {
      provide: IrisComponentsConstants.CONFIGURATION,
      useValue: {
        tagApi: environment.tagApi,
        categoriesApi: environment.categoriesApi,
        userApi: environment.userApi,
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
