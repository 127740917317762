import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CONSTANTS } from '@iris/iris-base';
import { Bank } from 'src/lib/models/bank.model';
import { BaseService } from '../base/base.service';

@Injectable()
export class BankService extends BaseService {
  private banksCache$: Observable<Bank[]>;
  iris = {
    id: CONSTANTS.IRIS_BANK_CODE.toString(),
    name: CONSTANTS.IRIS_BANK_NAME,
    nit: CONSTANTS.IRIS_BANK_CODE.toString(),
    number: CONSTANTS.IRIS_BANK_CODE,
    digit: 0,
    code: CONSTANTS.IRIS_BANK_CODE,
  } as Bank;

  get(): Observable<Bank[]> {
    if (!this.banksCache$) {
      this.banksCache$ = this.fetchBanks().pipe(shareReplay());
    }

    return this.banksCache$;
  }

  getWithIris(): Observable<Bank[]> {
    if (!this.banksCache$) {
      this.banksCache$ = this.fetchBanks().pipe(shareReplay());
    }

    return forkJoin([of(this.iris), this.banksCache$]).pipe(
      map(([iris, banks]) => {
        return [iris, ...banks];
      })
    );
  }

  getIrisBank(): Observable<Bank> {
    return of(this.iris);
  }

  private fetchBanks(): Observable<Bank[]> {
    return this.http.get<Bank[]>(this.settings.get.banksApi);
  }

  resetCache(): void {
    this.banksCache$ = undefined;
  }
}
