import { Injectable } from '@angular/core';
import { UserNamePipe } from 'src/lib/pipes/user-name.pipe';
import { UserService } from './user.service';
import { IMapUserName } from './user.types';

@Injectable()
export class UserMapperService {
  userNamePipe: UserNamePipe;

  constructor(
    private userService: UserService,
  ) {
    this.userNamePipe = new UserNamePipe();
  }

  map(entities: IMapUserName[]) {
    return this.userService
      .get(null, true)
      .toPromise()
      .then((users) => {
        entities.forEach((e) => {
          const user = users.find((u) => u.userId === e.userId);
          if (user) {
            e.userName = this.userNamePipe.transform(user, 'fullnameshort');
          }
        });
      });
  }
}
