import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { BaseService } from '../base/base.service';
import { SettingsService } from '../settings/settings.service';
import { Category } from "@iris/iris-components";

@Injectable()
export class CategoriesService extends BaseService {
  private categoriesCache$: any = undefined;

  constructor(
    protected http: HttpClient,
    protected settings: SettingsService,
    private translateService: TranslateService,
  ) {
    super(http, settings);
  }

  get(cached?: boolean): Observable<Category[]> {
    if (cached || cached === undefined) {
      const cache$ = this.categoriesCache$;

      if (!cache$) {
        this.categoriesCache$ = this.get(false).pipe(shareReplay());
      }

      return this.categoriesCache$;
    }

    return this.http.get<Category[]>(this.settings.get.categoriesApi).pipe(
      map(c => {
        return [
          {
            id: null,
            name: this.translateService.instant(
              'Administration.Tags.Transactions.NoCategory',
            ),
            color: '#3BFFEB',
          } as Category,
        ].concat(c);
      }),
    );
  }

  create(model: Category): Observable<string> {
    return this.http
      .post(this.settings.get.categoriesApi, model, {
        responseType: 'text',
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  update(model: Category): Observable<string> {
    return this.http
      .put(`${this.settings.get.categoriesApi}/${model.id}`, model, {
        responseType: 'text',
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  delete(id: string) {
    return this.http
      .delete(`${this.settings.get.categoriesApi}/${id}`, {
        responseType: 'text',
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  resetCache(): void {
    this.categoriesCache$ = undefined;
  }
}
