import { User } from './user.model';
import { SystemRole, Status } from 'src/lib/models/user.model';

export interface Area {
  id: string;
  name: string;
  userName?: string;
  description?: string;
  icon: string;
  status: boolean;
  totalCards : number;
  groupTotal?: number;
  totalUsers: number;
  startDate?: string;
  updateDate?: string;
  teams?: Team[];
  users?: User[];
  area?: string;
}

export interface AreaCreate {
  id?: string;
  name?: string;
  userName?: string;
  description?: string;
  icon: string;
  status: boolean;
  edited?: boolean;
  teams?: Team[];
  users?: User[];
}

export interface TeamCreate {
  id?: string;
  name: string;
  userName?: string;
  description?: string;
  users?: User[];
  area?: string;
}

export interface AreaFilter {
  users?: string[];
  enabled?: boolean;
}

export interface Team {
  id?: string;
  users?: User[];
  description?: string;
  name: string;
  userName?: string;
  area?: string;
  status?: boolean;
  startDate?: string;
  updateDate?: string;
  cards?: number;
}

export interface UserChangeTeam {
  user: string;
  teams: string;
}

export interface TeamFilter {
  id?: string[];
  name?: string;
  area?: string;
  team?: string;
  role?: SystemRole[];
  status?: Status[];
}

export enum CreateAreaResponse {
  AreaNotAvailableResponse = "There is an area created with the same name"
}

 export enum IconsAreasType{
  AREA01 = 'AREAS-01',
  AREA02 = 'AREAS-02',
  AREA03 = 'AREAS-03',
  AREA04 = 'AREAS-04',
  AREA05 = 'AREAS-05',
 }