import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '../models/user.model';

@Pipe({ name: 'userstatus' })
export class UserStatusPipe implements PipeTransform {
  transform(value: Status): string {
    switch (value) {
      case Status.New:
        return 'AccountManagement.UserManagement.Statuses.New';
      case Status.Active:
        return 'AccountManagement.UserManagement.Statuses.Active';
      case Status.Inactive:
        return 'AccountManagement.UserManagement.Statuses.Inactive';
      case Status.Blocked:
        return 'AccountManagement.UserManagement.Statuses.Blocked';
      case Status.ExpiredInvitation:
        return 'AccountManagement.UserManagement.Statuses.ExpiredInvitation';
    }
  }
}
