import { Pipe, PipeTransform } from '@angular/core';
import { InscribedAccountStatus } from '../models';

@Pipe({
  name: 'inscribedaccountstatus',
})
export class InscribedAccountStatusPipe implements PipeTransform {
  transform(value: InscribedAccountStatus): string {
    if (typeof value === 'string') {
      return `Transfers.InscribedAccounts.InscribedAccountStatus.${value}`;
    }
    return `Transfers.InscribedAccounts.InscribedAccountStatus.${InscribedAccountStatus[value]}`;
  }
}
