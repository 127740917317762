import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

@Directive({
  selector: '[valiDateRange]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateRangeDirective,
      multi: true,
    },
  ],
})
export class DateRangeDirective implements Validator {
  @Input('valiDateRange') dayRange: number;

  validate(control: AbstractControl): ValidationErrors | null {
    const date = new Date(control.value);
    const today = new Date();
    let maxDate = new Date();
    maxDate.setDate(today.getDate() + this.dayRange);
    if (this.dayRange >= 0) {
      if (date.getTime() < today.getTime()) {
        return { lowValue: true };
      }
      if (date.getTime() > maxDate.getTime()) {
        return { highValue: true };
      }
    } else {
      if (date.getTime() > today.getTime()) {
        return { highValue: true };
      }
      if (date.getTime() < maxDate.getTime()) {
        return { lowValue: true };
      }
    }

    return null;
  }
}
