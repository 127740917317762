import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepositStatisticsResponse } from 'src/lib/models/deposit.model';
import { BaseService } from '../base/base.service';
import { DepositFilters } from './deposit.types';

@Injectable()
export class DepositService extends BaseService {
  getDepositsStatistics(
    filters: DepositFilters,
  ): Observable<DepositStatisticsResponse> {
    return this.http.get<DepositStatisticsResponse>(
      `${this.settings.get.depositsApi}/statistics`,
      {
        params: filters as any,
      },
    );
  }
}
