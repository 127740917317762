import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IHandleDeniedOtp } from "@iris/iris-otp";

@Injectable({ providedIn: 'root' })
export class HandleDeniedOtp implements IHandleDeniedOtp {
  constructor(private router: Router) {}

  handle(): void {
    this.router.navigate(['/dashboard']);
  }
}
