export enum LogChannel {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum LogComponent {
  USERS_COMPONENT = "USERS_COMPONENT",
  ACCOUNT_COMPONENT = "ACCOUNT_COMPONENT",
  TAGS_COMPONENT = "TAGS_COMPONENT",
  ROLES_COMPONENT = "ROLES_COMPONENT",
  TRANSFER_COMPONENT = "TRANSFER_COMPONENT",
  INSCRIBED_ACCOUNT_COMPONENT = "INSCRIBED_ACCOUNT_COMPONENT",
  BATCHES_COMPONENT = "BATCHES_COMPONENT",
  AUTHORIZATION_COMPONENT = "AUTHORIZATION_COMPONENT",
  NOTIFICATION_COMPONENT = "NOTIFICATION_COMPONENT",
  LOGIN_COMPONENT = "LOGIN_COMPONENT",
  CARDS_COMPONENT = "CARDS_COMPONENT",
  AREA_COMPONENT = "AREA_COMPONENT",
  TEAM_COMPONENT = "TEAM_COMPONENT",
  GROUP_LIMITS_COMPONENT = "GROUP_LIMITS_COMPONENT",
  OPERATOR_COMPONENT = "OPERATOR_COMPONENT",
  TRANSACTION_COMPONENT = "TRANSACTION_COMPONENT",
  BALANCE_COMPONENT = "BALANCE_COMPONENT",
  PSE_COMPONENT = 'PSE_COMPONENT',

  IRISPAY_BILLING_COMPONENT = 'IRISPAY_BILLING_COMPONENT',
  IRISPAY_CONFIGURATION_COMPONENT = 'IRISPAY_CONFIGURATION_COMPONENT',
  COMPLEMENT_COMPONENT = 'COMPLEMENT_COMPONENT',
}

export interface Log {
  channel: LogChannel;
  createdAt: string;
  ip: string;
  userName: string;
  sourceDepositAccountEncodedKey: string;
  component: LogComponent;
  description: string;
}
