import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable, of } from 'rxjs';
import { ResumeFilterModel, ResumePagination, Transaction } from '../../models/transaction.model';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { BaseService } from '../base/base.service';
import { PaginatedResponse } from '../base/pagination.model';
import {
  CreateTransaction,
  TransactionFee,
  TransactionFilters,
  TransactionSummaryFilters
} from './transaction.types';

@Injectable()
export class TransactionService extends BaseService {
  get(filters: TransactionFilters): Observable<PaginatedResponse<Transaction>> {
    return this.http.get<PaginatedResponse<Transaction>>(
      this.settings.get.transactionsApi,
      {
        params: filters as any,
      },
    );
  }

  getById(encodedKey: string): Observable<Transaction> {
    return this.http.get<Transaction>(
      `${this.settings.get.transactionsApi}/${encodedKey}`,
    );
  }

  transfer(
    depositAccountId,
    model: CreateTransaction,
    otp: OtpViewModel,
  ): Observable<string> {
    return this.http.post(
      `${this.settings.get.transactionsApi}/${depositAccountId}/transfer`,
      model,
      {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  getTransferFee(
    depositAccountId,
    model: CreateTransaction,
  ): Observable<TransactionFee> {
    return this.http.post<TransactionFee>(
      `${this.settings.get.transactionsApi}/${depositAccountId}/fee`,
      model,
    );
  }

  getSummary(filters?: TransactionSummaryFilters): Observable<string> {
    if (!filters) {
      filters = {};
    }

    filters.uuid = uuidv4();

    return this.http
      .get<void>(`${this.settings.get.transactionsApi}/summary`, {
        params: filters as any,
      })
      .pipe(map(() => filters.uuid));
  }

  getTransfersStatistics(filters: any): Observable<any> {
    return this.http.get(
      `${this.settings.get.transactionsApi}/pending/statistics`, {
        params: filters
      }
    );
  }

  getAggregation(filters: ResumeFilterModel): Observable<ResumePagination> {
    return this.http.get(`${this.settings.get.transactionsApi}/aggregation`, {
      params: filters as any
    });
  }

  getCardTransactionById(encodedKey: string): Observable<Transaction> {
    return this.http.get<Transaction>(
      `${this.settings.get.eprepaidApi}/transactions/${encodedKey}`,
    );
  }

  getDataWidget(): Observable<any> {
    return of ([])
  }
}
