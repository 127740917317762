import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PendingTransaction } from '../../models';
import { BaseService } from '../base/base.service';
import { PaginatedResponse } from '../base/pagination.model';
import { Patch } from '../base/patch-operation.model';
import { PendingTransactionFilters } from './transaction.types';

@Injectable()
export class PendingTransactionService extends BaseService {
  get(
    filters: PendingTransactionFilters,
  ): Observable<PaginatedResponse<PendingTransaction>> {
    return this.http.get<PaginatedResponse<PendingTransaction>>(
      `${this.settings.get.transactionsApi}/pending`,
      {
        params: filters as any,
      },
    );
  }

  getById(id): Observable<PendingTransaction> {
    return this.http.get<PendingTransaction>(
      `${this.settings.get.transactionsApi}/pending/${id}`,
    );
  }

  update(id: number, payload: Patch[]) {
    return this.http.patch<PendingTransaction>(
      `${this.settings.get.transactionsApi}/pending/${id}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      },
    );
  }
}
