import { PendingTransaction } from 'src/lib/models/transaction.model';
import { Operation, TransactionStatus } from '../transaction/transaction.types';
import { IMapUserName } from '../user/user.types';

export enum BatchError {
  UNKNOWN_ERROR,
  INVALID_SOURCE_ACCOUNT,
  INVALID_DESTINATION_ACCOUNT_NUMBER,
  INVALID_DESTINATION_BANK_NUMBER,
  INVALID_DESTINATION_ACCOUNT_TYPE,
  INVALID_DESTINATION_ACCOUNT_OWNER,
  INVALID_DESTINATION_ACCOUNT_OWNER_DOCUMENT_TYPE,
  INVALID_DESTINATION_ACCOUNT_OWNER_DOCUMENT_NUMBER,
  INVALID_AMOUNT,
  INVALID_SCHEDULE_DATE,
  INVALID_INSCRIBE_ACCOUNT_TYPE,
  INVALID_SCHEDULE_TYPE,
}

export enum TransactionBatchShouldSchedule {
  Yes = 1,
  No = 2,
}

export enum TransactionBatchUpdateStatus {
  UNTOUCHED = 0,
  UPDATED = 1,
  DELETED = 2,
}

export interface Batch extends IMapUserName {
  id: number;
  type?: Operation;
  details: string;
  reason: string;
  tagId?: string;
  status?: TransactionStatus;
  createdAt?: Date;
  processedAt?: string;
  updatedAt?: Date;
  scheduleAt?: Date;
  totalTransactions: number;
  transactionsToProcess?: number;
  transactionsWithError?: number;
  totalAmount: number;
  authorizerId?: string;
  file?: { path?: string; name: string };
  transactions?: BatchTransaction[];
}

export interface BatchTransaction extends PendingTransaction {
  errors?: BatchError[];
  hasErrors?: boolean;
  updateStatus?: TransactionBatchUpdateStatus;
  inscribeAccount?: boolean;
  accountOwnerNotification?: boolean;
  inscribedAccountDescription?: string;
  inscribedAccountEmail?: string;
  inscribedAccountNotification?: boolean;
}

export interface InvalidBatchTransaction {
  id: string;
  transaction: BatchTransaction;
}

export interface BatchUuid {
  uuid: string
}