import { Pipe, PipeTransform } from '@angular/core';
import { UserDocumentType } from '../models/user.model';

@Pipe({ name: 'userDocumentType' })
export class UserDocumentTypePipe implements PipeTransform {
  transform(value): string {
    if (typeof value === 'string') {
      return `UserDocumentTypes.${value}`;
    }
    return `UserDocumentTypes.${UserDocumentType[value]}`;
  }
}

@Pipe({ name: 'userDocumentTypeLong' })
export class UserDocumentTypeLongPipe implements PipeTransform {
  transform(value): string {
    if (typeof value === 'string') {
      return `UserDocumentTypes.${value}_Long`;
    }
    return `UserDocumentTypes.${UserDocumentType[value]}_Long`;
  }
}
