export const environment = {
  production: false,
  idleTimeout: 5,
  eprepaidApi: 'https://api.dev.irsbnk.co/iriscard',
  transactionsApi: 'https://api.dev.irsbnk.co/empresas-financial/transactions',
  notificationsApi: 'https://api.dev.irsbnk.co/empresas/notifications',
  depositAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial/depositaccounts',
  dailyBalancesApi:
    'https://api.dev.irsbnk.co/empresas-financial/dailybalances',
  accountApi: 'https://api.dev.irsbnk.co/empresas/account',
  userApi: 'https://api.dev.irsbnk.co/empresas/users',
  approvalEprepaidApi: 'https://api.dev.irsbnk.co/eprepaid/approval',
  groupApi: 'https://api.dev.irsbnk.co/empresas-financial/groups',
  roleApi: 'https://api.dev.irsbnk.co/empresas/roles',
  otpApi: 'https://api.dev.irsbnk.co/empresas/otp',
  banksApi: 'https://api.dev.irsbnk.co/empresas-financial/banks',
  tagApi: 'https://api.dev.irsbnk.co/empresas/tags',
  registerAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial/inscribedaccounts',
  batchApi: 'https://api.dev.irsbnk.co/empresas-financial/batches',
  logsApi: 'https://api.dev.irsbnk.co/empresas/auditlogs',
  authorizationsApi:
    'https://api.dev.irsbnk.co/empresas-financial/authorizations',
  exportsApi: 'https://api.dev.irsbnk.co/empresas-financial/exports',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas/login',
  loansApi: 'https://api.dev.irsbnk.co/empresas-financial/loans',
  plansApi: 'https://api.dev.irsbnk.co/empresas-financial/plans',
  categoriesApi: 'https://api.dev.irsbnk.co/empresas/categories',
  billingApi: 'https://api.dev.irsbnk.co/irispay/billing',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket',
  staticApiKey: 'sbzctHBO6Z8u5AtmnKlRb8lTkUmSzwYU6JuW1Nwg',
  sessionConfiguration: {
    domain: 'empresas.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  addOnsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
  depositsApi: 'https://api.dev.irsbnk.co/empresas-financial/deposits',
  metricsApi: 'https://api.dev.irsbnk.co/empresas-financial/metrics',
};
