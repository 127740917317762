import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Area, AreaFilter, User, AreaCreate } from 'src/lib/models';
import { BaseService } from '../base/base.service';
import { UserFilter } from '../user/user.types';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';

@Injectable()
export class AreasService extends BaseService {
  private areasCaches$: any = {};
  private userCaches$: any = {};
  private areaModel: Area;

  get(filters?: AreaFilter, cached?: boolean): Observable<Area[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.areasCaches$[key];

      if (!cache$) {
        this.areasCaches$[key] = this.get(filters, false).pipe(shareReplay());
      }

      return this.areasCaches$[key];
    }

    return this.http
      .get<Area[]>(`${this.settings.get.eprepaidApi}/area/description`, {
        params: filters as any,
      })
      .pipe(map(areas => areas.sort((a, b) => a.name.localeCompare(b.name))));
  }

  getById(areaId: string): Observable<Area> {
    return this.http.get<Area>(
      `${this.settings.get.eprepaidApi}/area/${areaId}`,
    );
  }

  getUserArea(filters: UserFilter = {}, cached?: boolean): Observable<User[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.userCaches$[key];

      if (!cache$) {
        this.userCaches$[key] = this.getUserArea(filters, false).pipe(
          shareReplay(),
        );
      }

      return this.userCaches$[key];
    }

    return this.http.get<User[]>(
      `${this.settings.get.eprepaidApi}/user/detail`,
      {
        params: filters as HttpParams,
      },
    );
  }

  create(area: AreaCreate, otp: OtpViewModel): Observable<any> {
    return this.http
      .post(`${this.settings.get.eprepaidApi}/area`, area, {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  resetCache(): void {
    this.areasCaches$ = {};
  }

  enable(area: Area) {
    return this.http
      .put(`${this.settings.get.eprepaidApi}/area/${area.id}/enable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  disable(area: Area) {
    return this.http
      .put(`${this.settings.get.eprepaidApi}/area/${area.id}/disable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  getAreaById(area: string = '') {
    return this.http
      .get<any>(`${this.settings.get.eprepaidApi}/area/${area}`)
      .pipe(
        map(res => {
          return res.name;
        }),
      );
  }

  editArea(area: Area, areaId: string, otp: OtpViewModel) {
    return this.http.put<any>(
      `${this.settings.get.eprepaidApi}/area/${areaId}`,
      area,
      {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }
}
