import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IHandleNetworkIssues, NetworkErrorType } from '@iris/iris-base';
import { SessionService } from '@iris/iris-authentication';

@Injectable({ providedIn: 'root' })
export class HandleNoInternetService implements IHandleNetworkIssues {
  constructor(private sessionService: SessionService, private router: Router) {}

  action(type: NetworkErrorType): void {
    switch (type) {
      case NetworkErrorType.InternalServerError:
        window.location.reload();
        break;
      case NetworkErrorType.NoConnection:
        this.router
          .navigateByUrl('/refresh', { skipLocationChange: true })
          .then(() => {
            window.location.href = '/login';
          });
        break;
    }
  }

  handle(type: NetworkErrorType): void {
    if (type === NetworkErrorType.NoConnection) {
      this.sessionService.signOut();
    }
  }
}
