import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Team, TeamCreate } from 'src/lib/models';
import { TeamFilter } from './team.types';
import { BaseService } from '../base/base.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { OtpViewModel, IrisOtpConstants } from '@iris/iris-otp';

@Injectable()
export class TeamService extends BaseService {
  private teamsCaches$: any = {};

  get(filters?: TeamFilter, cached?: boolean): Observable<Team[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.teamsCaches$[key];

      if (!cache$) {
        this.teamsCaches$[key] = this.get(filters, false).pipe(shareReplay());
      }

      return this.teamsCaches$[key];
    }

    return this.http
      .get<Team[]>(`${this.settings.get.eprepaidApi}/team`, {
        params: filters as any,
      })
      .pipe(map(teams => teams.sort((a, b) => a.name.localeCompare(b.name))));
  }

  create(areaId: string, teams: TeamCreate[], otp: OtpViewModel): Observable<string> {
    let params = new HttpParams();
    params = params.append('area', areaId);
    return this.http
      .post(`${this.settings.get.eprepaidApi}/team`, teams, {
        params: params,
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        }
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  update(teamId: string, payload: TeamCreate, otp: OtpViewModel) {
    return this.http
      .put(`${this.settings.get.eprepaidApi}/team/${teamId}`, payload, {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        }
      })
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  changeTeam(userId: string, teamId: string): Observable<any> {
    return this.http.put(
      `${this.settings.get.eprepaidApi}/user/${userId}`,
      {},
      {
        params: { team: teamId },
      },
    );
  }

  enable(team: Team) {
    return this.http
      .put(`${this.settings.get.eprepaidApi}/team/${team.id}/enable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  disable(team: Team) {    
    return this.http
      .put(`${this.settings.get.eprepaidApi}/team/${team.id}/disable`, null)
      .pipe(
        tap(() => {
          this.resetCache();
        }),
      );
  }

  resetCache(): void {
    this.teamsCaches$ = {};
  }

  getAllTeams() {
    return this.http.get<any>(`${this.settings.get.eprepaidApi}/team`).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getTeamById(team: string = '') {
    return this.http
      .get<any>(`${this.settings.get.eprepaidApi}/team/${team}`)
      .pipe(
        map(res => {
          return res.name;
        }),
      );
  }
}
