import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DraftPaymentsFilters } from 'src/app/payment/draft-payments/draft-payments.model';
import { TransactionRejection } from 'src/lib/models/transaction.model';
import { BaseService } from '../base/base.service';
import { PaginatedRequest, PaginatedResponse } from '../base/pagination.model';
import { Operation } from '../transaction/transaction.types';
import { Batch, BatchUuid } from './batch.types';
import { v4 as uuidv4 } from 'uuid';
import { Patch } from '../base/patch-operation.model';

@Injectable()
export class BatchService extends BaseService {
  get(filters: PaginatedRequest): Observable<PaginatedResponse<Batch>> {
    return this.http.get<PaginatedResponse<Batch>>(this.settings.get.batchApi, {
      params: filters as any,
    });
  }

  getById(batchId: string): Observable<Batch> {
    return this.http.get<Batch>(`${this.settings.get.batchApi}/${batchId}`);
  }

  approve(batchIds: number[], otp: OtpViewModel): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.settings.get.batchApi}/approve`,
      batchIds,
      {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  eliminate(
    batchesToReject: TransactionRejection,
    otp: OtpViewModel,
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.settings.get.batchApi}/eliminate`,
      batchesToReject,
      {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  nameAlreadyExists(details: string): Observable<any> {
    let params = new HttpParams().set('details', details);
    return this.http.get<any>(`${this.settings.get.batchApi}/exists`, {
      params,
    });
  }

  getDrafts(
    params: DraftPaymentsFilters,
  ): Observable<PaginatedResponse<Batch>> {
    return this.http.get<PaginatedResponse<Batch>>(
      `${this.settings.get.batchApi}/preprocess`,
      {
        params: params as any,
      },
    );
  }

  preprocess(batchId: string): Observable<Batch> {
    return this.http.get<Batch>(
      `${this.settings.get.batchApi}/preprocess/${batchId}`,
    );
  }

  downloadFile(operation?: Operation): Observable<string> {
    return this.http.get(
      `${this.settings.get.batchApi}/preprocess/template/${operation}`,
      {
        responseType: 'text',
      },
    );
  }

  uploadFile(formData, id?: string, isFile?: boolean): Observable<any> {
        if (isFile){
      return this.http.post(
        `${this.settings.get.batchApi}/preprocess/file`,
        formData,
        {
          responseType: 'text',
        },
      );
    }
    if (id) {
      return this.http.patch(
        `${this.settings.get.batchApi}/preprocess/${id}`,
        formData,
        {
          responseType: 'text',
        },
      );
    } else {
      return this.http.post(
        `${this.settings.get.batchApi}/preprocess`,
        formData,
        {
          responseType: 'text',
        },
      );
    }
  }

  uploadManualEntries(formData, otp: OtpViewModel): Observable<any> {
    return this.http.post(`${this.settings.get.batchApi}`, formData, {
      headers: {
        [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
        [IrisOtpConstants.OtpIdHeader]: otp.otpId,
      },
    });
  }

  downloadUpdatedFile(id: string): Observable<Blob> {
    return this.http
      .get(`${this.settings.get.batchApi}/preprocess/${id}/file`, {
        responseType: 'text',
      })
      .pipe(
        map(r => {
          return new Blob([r], {
            type: 'text/plain',
          });
        }),
      );
  }

  create(batchId: string, otp: OtpViewModel): Observable<BatchUuid> {
    return this.http.post<BatchUuid>(`${this.settings.get.batchApi}/${batchId}`, null, {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      });
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.settings.get.batchApi}/preprocess/${id}`);
  }

  update(id: number, payload: Patch[]) {
    const uuid = uuidv4();

    return this.http
      .patch<void>(`${this.settings.get.batchApi}/${id}`, payload, {
        params: new HttpParams().set('uuid', uuid),
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      })
      .pipe(
        map(() => {
          return uuid;
        }),
      );
  }
}
