import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iriscurrency',
})
export class IrisCurrencyPipe implements PipeTransform {
  private decimalPipe: DecimalPipe;

  constructor() {
    this.decimalPipe = new DecimalPipe('en-US');
  }

  transform(value: any, truncate: boolean = false) {
    if (truncate) {
      value = (value / 1000).toFixed(2);
    }
    if (value < 0) {
      return `-$${this.decimalPipe.transform(Math.abs(value) || 0, '1.2')}`;
    }
    return `$${this.decimalPipe.transform(value || 0, '1.2')}`;
  }
}
