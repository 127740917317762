import { Operation } from '../services/transaction/transaction.types';

export enum OperationType {
  Transfer = 'TRANSFER',
  BatchTransfer = 'BATCH_TRANSFER',
  PaymentsProviders = 'PAYMENTS_PROVIDERS',
  PaymentsPayroll = 'PAYMENTS_PAYROLL',
  Payments = 'PAYMENTS',
  WITHDRAWAL_TRANSACTIONS = 'WITHDRAWAL_TRANSACTIONS',
  DEPOSIT_TRANSACTIONS = 'DEPOSIT_TRANSACTIONS',
}

export const OperationCategories = {
  [OperationType.Transfer]: [
    Operation.TRANSACTIONS_OWN_ACCOUNTS,
    Operation.TRANSACTIONS_ACCOUNTS_SAME_BANK,
    Operation.TRANSACTIONS_ACCOUNTS_OTHER_BANK,
  ],
  [OperationType.BatchTransfer]: [Operation.TRANSACTIONS_BATCHES],
  [OperationType.PaymentsProviders]: [
    Operation.PAYMENTS_PROVIDERS_MANUAL,
    Operation.PAYMENTS_PROVIDERS_BATCHES,
  ],
  [OperationType.PaymentsPayroll]: [
    Operation.PAYMENTS_PAYROLL_MANUAL,
    Operation.PAYMENTS_PAYROLL_BATCHES,
  ],
};

export const OperationTypes = [OperationType.Transfer, OperationType.Payments];
