export enum TransactionLimit {
  NOT_REQUIRED = 'NOT_REQUIRED',
  MAX = 'MAX',
  UNLIMITED = 'UNLIMITED',
  MAXIMUM_WEEK = 'MAXIMUM_WEEK',
  MAXIMUM_FORTNIGHT = 'MAXIMUM_FORTNIGHT',
  MAXIMUM_MONTH = 'MAXIMUM_MONTH',
  MAXIMUM_TWO_MONTH = 'MAXIMUM_TWO_MONTH',
  MAXIMUM_THREE_MONTH = 'MAXIMUM_THREE_MONTH',
}
