import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localdate',
})
export class LocalDatePipe implements PipeTransform {
  datePipe: DatePipe;
  offset: number = null;
  formats = {
    shortDate: 'dd/MM/yyyy',
    shortTime: 'hh:mm a',
    medium: 'dd MMM yyyy, hh:mm a',
    complete: 'dd/MM/yyyy hh:mm a',
  };

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.datePipe = new DatePipe(locale);
    this.offset = Math.trunc(new Date().getTimezoneOffset() / 60);
  }

  transform(value: any, format: string) {
    format = this.formats[format] ? this.formats[format] : format;
    const date = value ? moment(value) : null;

    // If date is not specifically UTC, converts it to local
    if (
      date &&
      typeof value === 'string' &&
      !value.includes('Z') &&
      !value.includes('+00:00')
    ) {
      date.subtract(this.offset, 'hours');
    }

    return this.datePipe.transform(date?.toDate(), format);
  }
}
