import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResumePagination, TagSummaryFilterModel } from 'src/app/administration/tags/components/tags-resume-card/components/iris-pay/components/tags-recaudo-resume/tags.resume.types';
import { BaseService } from '../base/base.service';
import { BillingFilters } from './billing.types';
import { PaginatedResponse } from '../base/pagination.model';
import { Billing } from '../../models/billing.model';

@Injectable()
export class BillingService extends BaseService {

  public getAggregation(filters: TagSummaryFilterModel): Observable<ResumePagination> {
    return this.http.get(`${this.settings.get.billingApi}/aggregation`, {
      params: filters as any
    });
  }


  public get(filters: BillingFilters): Observable<PaginatedResponse<Billing>> {
    return this.http.get<PaginatedResponse<Billing>>(
      this.settings.get.billingApi,
      {
        params: filters as any,
      },
    );
  }

}
