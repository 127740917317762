export enum Level {
  Error = 0,
  Warning = 1,
}

export enum InformationType {
  FreeInterbankTransactionsReached = 'FreeInterbankTransactionsReached',
  MinimumBalanceNotReached = 'MinimumBalanceNotReached',
}

export interface PlanInformation {
  type: InformationType;
  level: Level;
  details?: any;
}
