import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base/base.service';
import {
  DatePayload,
  GroupedTransaction,
  MainTransfer,
  MainTransfersFilter,
  DatePayloadIrisPay,
  TotalCollectionSwiper,
  TotalMovements,
  TransferInfo,
} from './metrics.types';

@Injectable()
export class MetricsService extends BaseService {
  getMainDataOfRecurrence(model: MainTransfersFilter): Observable<MainTransfer[]> {
    return this.http.get<MainTransfer[]>(
      `${this.settings.get.metricsApi}/${'main_payments'}`,
      {
        params: model as any,
      },
    );
  }

  getTransfers(model: DatePayload): Observable<TransferInfo[]> {
    return this.http.get<TransferInfo[]>(
      `${this.settings.get.metricsApi}/${'transfers_info'}`,
      {
        params: model as any,
      },
    );
  }

  getGroupedTransactions(model: DatePayload): Observable<GroupedTransaction[]> {
    return this.http.get<GroupedTransaction[]>(
      `${this.settings.get.metricsApi}/${'grouped_transactions'}`,
      {
        params: model as any,
      },
    );
  }

  getTotalMovements(model: DatePayload): Observable<TotalMovements> {
    return this.http.get<TotalMovements>(
      `${this.settings.get.metricsApi}/${'total_movements'}`,
      {
        params: model as any,
      },
    );
  }

  getDataCollect(params :DatePayloadIrisPay):Observable<TotalCollectionSwiper>{
    return of({
      totalCollect: 0,//18000000,
      cards: [
        { type: '1', totalPayment: 70, totalCollect: 80000000 }, 
        { type: '0', totalPayment: 30, totalCollect: 10000000 },
      ],
    })
  }

  getTransactionCollection(params: DatePayloadIrisPay): Observable<any> {
    return of([
      // { TODO: Pendiente por habilitar
      //   date: '2023-06-15T00:00:00',
      //   income: 2000,
      // },
      // {
      //   date: '2023-06-16T00:00:00',
      //   income: 5000,
      // },
      // {
      //   date: '2023-06-24T00:00:00',
      //   income: 1000,
      // },
      // {
      //   date: '2023-06-06T00:00:00',
      //   income: 6000,
      // },
      // {
      //   date: '2023-06-08T00:00:00',
      //   income: 9000,
      // },
      // {
      //   date: '2023-06-13T00:00:00',
      //   income: 3000,
      // },
      // {
      //   date: '2023-09-08T00:00:00',
      //   income: 3000,
      // },
      // {
      //   date: '2023-09-14T00:00:00',
      //   income: 5000,
      // },
      // {
      //   date: '2023-09-16T00:00:00',
      //   income: 6000,
      // },
      // {
      //   date: '2023-09-03T00:00:00',
      //   income: 5000,
      // },
      // {
      //   date: '2023-09-14T00:00:00',
      //   income: 1000,
      // },
      // {
      //   date: '2023-09-02T00:00:00',
      //   income: 1000,
      // },
    ]);
  }

  getTransactionIrisCard (params: DatePayloadIrisPay): Observable<any> {
    return of([] )
  }
}
