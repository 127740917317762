import { Pipe, PipeTransform } from '@angular/core';
import { TransactionLimit } from 'src/app/user-management/roles/components/role-permissions/role-permissions.types';

@Pipe({ name: 'transactionlimit' })
export class TransactionLimitPipe implements PipeTransform {
  transform(value: TransactionLimit): string {
    if (typeof value === 'string') {
      return `TransactionLimit.${value}`;
    }
    return `TransactionLimit.${TransactionLimit[value]}`;
  }
}
