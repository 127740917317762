export enum AsyncOperationType {
  DAILY_BALANCE_BY_ACCOUNT_REPORT = 'DAILY_BALANCE_BY_ACCOUNT_REPORT',
  DAILY_BALANCE_ACCUMULATED = 'DAILY_BALANCE_ACCUMULATED',
  TRANSACTIONS_SUMMARY = 'TRANSACTIONS_SUMMARY',
  EXPORT_TRANSACTIONS_DETAILS = 'EXPORT_TRANSACTIONS_DETAILS',
  EXPORT_PENDING_TRANSACTIONS = 'EXPORT_PENDING_TRANSACTIONS',
  TRANSACTION_STATISTICS = 'TRANSACTION_STATISTICS',
  TRANSACTIONS_AGGREGATION_REPORT = 'TRANSACTIONS_AGGREGATION_REPORT',
  UPDATE_BATCH_TRANSACTIONS = 'UPDATE_BATCH_TRANSACTIONS',
  DEPOSIT_STATISTICS = 'DEPOSIT_STATISTICS',
  RECAUDOS_AGGREGATION_REPORT = 'RECAUDOS_AGGREGATION_REPORT',
  PROCESS_BATCH_TRANSACTIONS = 'PROCESS_BATCH_TRANSACTIONS',
  PROCESS_PAYMENT_BATCH_TRANSACTIONS = 'PROCESS_PAYMENT_BATCH_TRANSACTIONS',
  IRISCARD_BUDGET_TRANSACTION = 'IRISCARD_BUDGET_TRANSACTION',
}

export interface AsyncOperationViewModel<T> {
  result: T;
  status: number;
  type: AsyncOperationType;
  uuid: string;
  error?: string;
}
