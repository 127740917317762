import { Pipe, PipeTransform } from '@angular/core';
import { DepositAccountType } from '../models/depositaccount.model';

@Pipe({ name: 'depositaccounttype' })
export class DepositAccountTypePipe implements PipeTransform {
  transform(value, format?): string {
    const suffix = format !== 'short' ? '_Long' : '';

    if (typeof value === 'string') {
      return `DepositAccountType.${value}${suffix}`;
    }

    return `DepositAccountType.${DepositAccountType[value]}${suffix}`;
  }
}
