import { Pipe, PipeTransform } from '@angular/core';
import { Operation } from '../services/transaction/transaction.types';

@Pipe({
  name: 'operation',
})
export class OperationPipe implements PipeTransform {
  transform(value: Operation, format = 'short'): string {
    const suffix = format !== 'short' ? '_Long' : '';
    let type = '';
    switch (value) {
      case Operation.TRANSACTIONS_OWN_ACCOUNTS:
      case Operation.TRANSACTIONS_ACCOUNTS_SAME_BANK:
      case Operation.TRANSACTIONS_ACCOUNTS_OTHER_BANK:
      case Operation.TRANSACTIONS_BATCHES:
        type = 'TRANSACTIONS';
        break;

      case Operation.PAYMENTS_PAYROLL_MANUAL:
      case Operation.PAYMENTS_PROVIDERS_MANUAL:
      case Operation.PAYMENTS_PAYROLL_BATCHES:
      case Operation.PAYMENTS_PROVIDERS_BATCHES:
        type = 'PAYMENTS';
        break;
      case Operation.PSE:
        type = 'PSE';
    }

    if (typeof value === 'string') {
      return `AccountManagement.RoleManagement.Modules.${type}.Permissions.${value}${suffix}`;
    }
    return `AccountManagement.RoleManagement.Modules.${type}.Permissions.${Operation[value]}${suffix}`;
  }
}
