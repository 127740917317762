import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalktroughComponent } from './walktrough.component';
import { BaseModule } from 'src/app/base/base.module';
import { SwiperModule } from 'swiper/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WalktroughComponent],
  imports: [CommonModule, BaseModule, SwiperModule, TranslateModule.forChild()],
  exports: [WalktroughComponent],
})
export class WalktroughModule {}
