import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maskAccount' })
export class MaskAccountPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'string') {
      return '****'+value.slice(-4);
    }

    return '****'+value.slice(-4);
  }
}
