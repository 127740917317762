import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'countryname'
})
export class CountryNamePipe implements PipeTransform {
  private dictionary = {
    "ABW": "Aruba",
    "AFG": "Afganistán",
    "AGO": "Angola",
    "AIA": "Anguilla",
    "ALB": "Albania",
    "AND": "Andorra",
    "ARE": "Emiratos árabes Unidos",
    "ARG": "Argentina",
    "ARM": "Armenia",
    "ASM": "Samoa Americana",
    "ATA": "Antártida",
    "ATF": "Tierras Australes y Antárticas Francesas",
    "ATG": "Antigua y Barbuda",
    "AUS": "Australia",
    "AUT": "Austria",
    "AZE": "Azerbaiyán",
    "BDI": "Burundi",
    "BEL": "Belgica",
    "BEN": "Benin",
    "BES": "Caribe Neerlandés",
    "BFA": "Burkina Faso",
    "BGD": "Bangladés",
    "BGR": "Bulgaria",
    "BHR": "Baréin",
    "BHS": "Bahamas",
    "BIH": "Bosnia y Herzegovina",
    "BLM": "San Bartolomé",
    "BLR": "Bielorussia",
    "BLZ": "Belice",
    "BMU": "Bermudas",
    "BOL": "Bolivia",
    "BRA": "Brasil",
    "BRB": "Barbados",
    "BRN": "Brunéi",
    "BTN": "Bután",
    "BWA": "Botsuana",
    "CAF": "República Centroafricana",
    "CAN": "Canadá",
    "CCK": "Islas Cocos",
    "CHE": "Suiza",
    "CHL": "Chile",
    "CHN": "China",
    "CIV": "Costa de Marfil",
    "CMR": "Camerún",
    "COD": "República Democrática del Congo",
    "COG": "República del Congo",
    "COK": "Islas Cook",
    "COL": "Colombia",
    "COM": "Comoras",
    "CPV": "Cabo Verde",
    "CRI": "Costa Rica",
    "CUB": "Cuba",
    "CUW": "Curazao",
    "CXR": "Isla de Navidad",
    "CYM": "Islas Caimán",
    "CYP": "Chipre",
    "CZE": "República Checa",
    "DEU": "Alemania",
    "DJI": "Yibuti",
    "DMA": "Dominica",
    "DNK": "Dinamarca",
    "DOM": "República Dominicana",
    "DZA": "Argelia",
    "ECU": "Ecuador",
    "EGY": "Egipto",
    "ERI": "Eritrea",
    "ESH": "República árabe Saharaui Democrática",
    "ESP": "España",
    "EST": "Estonia",
    "ETH": "Etiopía",
    "FIN": "Finlandia",
    "FJI": "Fiyi",
    "FLK": "Islas Malvinas",
    "FRA": "Francia",
    "FRO": "Islas Feroe",
    "FSM": "Estados Federados de Micronesia",
    "GAB": "Gabon",
    "GBR": "Reino Unido",
    "GEO": "Georgia",
    "GGY": "Guernsey",
    "GHA": "Ghana",
    "GIB": "Gibraltar",
    "GIN": "Guinea",
    "GLP": "Guadelupe",
    "GMB": "Gambia",
    "GNB": "Guinea-Bissau",
    "GNQ": "Guinea Ecuatorial",
    "GRC": "Grecia",
    "GRD": "Granada",
    "GRL": "Groenlandia",
    "GTM": "Guatemala",
    "GUF": "Guayana Francesa",
    "GUM": "Guam",
    "GUY": "Guyana",
    "HKG": "Hong Kong",
    "HND": "Honduras",
    "HRV": "Croacia",
    "HTI": "Haití",
    "HUN": "Hungría",
    "IDN": "Indonesia",
    "IMN": "Isla de Man",
    "IND": "India",
    "IOT": "Territorio Británico del Océano índico",
    "IRL": "Islandia",
    "IRN": "Iran",
    "IRQ": "Irak",
    "ISL": "Iceland",
    "ISR": "Israel",
    "ITA": "Italia",
    "JAM": "Jamaica",
    "JEY": "Jersey",
    "JOR": "Jordania",
    "JPN": "Japón",
    "KAZ": "Kazajistán",
    "KEN": "Kenia",
    "KGZ": "Kirguistán",
    "KHM": "Camboya",
    "KIR": "Kiribati",
    "KNA": "San Cristóbal y Nieves",
    "KOR": "Corea del Sur",
    "KWT": "Kuweit",
    "LAO": "Laos",
    "LBN": "Libano",
    "LBR": "Liberia",
    "LBY": "Libia",
    "LCA": "Santa Lucía",
    "LIE": "Liechtenstein",
    "LKA": "Sri Lanka",
    "LSO": "Lesoto",
    "LTU": "Lituania",
    "LUX": "Luxemburgo",
    "LVA": "Letonia",
    "MAC": "Macao",
    "MAF": "San Martín",
    "MAR": "Marruecos",
    "MCO": "Monaco",
    "MDA": "Moldavia",
    "MDG": "Madagascar",
    "MDV": "Maldivas",
    "MEX": "México",
    "MHL": "Islas Marshall",
    "MKD": "Macedonia",
    "MLI": "Mali",
    "MLT": "Malta",
    "MMR": "Birmania",
    "MNE": "Monténégro",
    "MNG": "Mongolia",
    "MNP": "Islas Marianas del Norte",
    "MOZ": "Mozambique",
    "MRT": "Mauritania",
    "MSR": "Montserrat",
    "MTQ": "Martinica",
    "MUS": "Mauricio",
    "MWI": "Malaui",
    "MYS": "Malasia",
    "MYT": "Mayotte",
    "NAM": "Namibia",
    "NCL": "Nueva Caledonia",
    "NER": "Niger",
    "NFK": "Isla Norfolk",
    "NGA": "Nigeria",
    "NIC": "Nicaragua",
    "NIU": "Niue",
    "NLD": "Países Bajos",
    "NOR": "Noruega",
    "NPL": "Nepal",
    "NRU": "Nauru",
    "NZL": "Nueva Zelanda",
    "OMN": "Omán",
    "PAK": "Pakistán",
    "PAN": "Panama",
    "PCN": "Islas Pitcairn",
    "PER": "Perú",
    "PHL": "Filipinas",
    "PLW": "Palaos",
    "PNG": "Papúa Nueva Guinea",
    "POL": "Polonia",
    "PRI": "Puerto Rico",
    "PRK": "Corea del Norte",
    "PRT": "Portugal",
    "PRY": "Paraguay",
    "PSE": "Palestina",
    "PYF": "Polinesia Francesa",
    "QAT": "Catar",
    "REU": "Reunión",
    "ROU": "Rumania",
    "RUS": "Rusia",
    "RWA": "Ruanda",
    "SAU": "Arabia Saudí",
    "SDN": "Sudán",
    "SEN": "Senegal",
    "SGP": "Singapur",
    "SGS": "Islas Georgias y Sandwich del Sur",
    "SHN": "Sint Helena, Ascension and Tristan da Cunha",
    "SLB": "Islas Salomón",
    "SLE": "Sierra Leona",
    "SLV": "El Salvador",
    "SMR": "San Marino",
    "SOM": "Somalia",
    "SPM": "San Pedro y Miquelón",
    "SRB": "Serbia",
    "SSD": "Sudán del Sur",
    "STP": "Santo Tomé y Príncipe",
    "SUR": "Surinam",
    "SVK": "Eslovaquia",
    "SVN": "Eslovenia",
    "SWE": "Suecia",
    "SWZ": "eSwatani",
    "SXM": "Sint-Maarten",
    "SYC": "Seychelles",
    "SYR": "Siria",
    "TCA": "Islas Turcas y Caicos",
    "TCD": "Chad",
    "TGO": "Togo",
    "THA": "Tailandia",
    "TJK": "Tayikistán",
    "TKL": "Tokelau",
    "TKM": "Turkmenistán",
    "TLS": "Timor Oriental",
    "TON": "Tonga",
    "TTO": "Trinidad y Tobago",
    "TUN": "Túnez",
    "TUR": "Turquía",
    "TUV": "Tuvalu",
    "TWN": "Taiwan",
    "TZA": "Tanzania",
    "UGA": "Uganda",
    "UKR": "Ucrania",
    "URY": "Uruguay",
    "USA": "Estados Unidos",
    "UZB": "Uzbekistán",
    "VAT": "Ciudad del Vaticano",
    "VCT": "San Vicente y las Granadinas",
    "VEN": "Venezuela",
    "VGB": "Islas Vírgenes Británicas",
    "VIR": "Islas Vírgenes de los Estados Unidos",
    "VNM": "Vietnam",
    "VUT": "Vanuatu",
    "WLF": "Wallis y Futuna",
    "WSM": "Samoa",
    "XKX": "Kosovo",
    "YEM": "Yemen",
    "ZAF": "Sudáfrica",
    "ZMB": "Zambia",
    "ZWE": "Zimbabwe",
  }

  transform(value: string): any {
    const country = this.dictionary[value];

    return country || '-';
  }
}