import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from '../services/transaction/transaction.types';

@Pipe({
  name: 'transferstatus',
})
export class TransferStatusPipe implements PipeTransform {
  transform(value: TransactionStatus): string {
    if (TransactionStatus[value] === undefined) {
      return '';
    } else {
      return `Transactions.ScheduledTransactions.Status.${TransactionStatus[value]}`;
    }
  }
}
