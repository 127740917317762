import { IrisCardStatus } from './../enums/iriscard-status';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iriscardstatus',
})
export class IrisCardStatusPipe implements PipeTransform {
  transform(value: IrisCardStatus): string {
    if (IrisCardStatus[value] === undefined) {
      return '';
    } else {
      return `Transactions.ScheduledIrisCard.Status.${IrisCardStatus[value]}`;
    }
  }
}
