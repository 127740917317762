import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionreference',
})
export class TransactionReferencePipe implements PipeTransform {
  transform(value: string) {
    return value.padStart(17, '0');
  }
}
