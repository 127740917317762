import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DepositAccount,
  DepositAccountType,
} from '../models/depositaccount.model';
import { InscribedAccount } from '../models/inscribed-account.model';
import { DepositAccountTypePipe } from './deposit-account-type.pipe';
import { DepositAccountMaskPipe } from './deposit-account-mask.pipe';

@Pipe({ name: 'depositaccount' })
export class DepositAccountPipe implements PipeTransform {
  private depositAccountTypePipe: DepositAccountTypePipe;
  private depositAccountMaskPipe: DepositAccountMaskPipe;

  constructor(private translateService: TranslateService) {
    this.depositAccountTypePipe = new DepositAccountTypePipe();
    this.depositAccountMaskPipe = new DepositAccountMaskPipe();
  }

  transform(value: DepositAccount | InscribedAccount): string {
    let string = '';

    if (value.hasOwnProperty('mambuName')) {
      //DepositAccount
      const depositAccount = value as DepositAccount;

      string += depositAccount.name;
      string += ` ${this.depositAccountMaskPipe.transform(depositAccount.id)}`;
    } else {
      //InscribedAccount
      const inscribedAccount = value as InscribedAccount;

      string += inscribedAccount.accountOwner;
      string += ` ${this.depositAccountMaskPipe.transform(
        inscribedAccount.accountId
      )}`;
    }

    return string;
  }

  private getAccountType(accountType) {
    const transformed = this.depositAccountTypePipe.transform(
      accountType,
      'short'
    );
    return this.translateService.instant(transformed);
  }
}
