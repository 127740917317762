import { StatusCodes } from '@iris/iris-base';
import { WebsocketMessage, WebsocketOperation } from '@iris/iris-authentication';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  AsyncOperationType,
  AsyncOperationViewModel,
} from '../models/async.model';

export function filterWebsocketOperation(
  operation: WebsocketOperation,
): MonoTypeOperatorFunction<WebsocketMessage> {
  return pipe(filter(r => r.operation === operation));
}

export function mapAsyncResponse<T>() {
  return pipe(
    map(
      (r: WebsocketMessage) => r.data as AsyncOperationViewModel<T>,
    ),
  );
}

export function filterAsyncResponse(
  uuid: string,
  type: AsyncOperationType,
  statusCode: StatusCodes = StatusCodes.OK,
): MonoTypeOperatorFunction<AsyncOperationViewModel<any>> {
  return pipe(
    filter(r => r.uuid === uuid && r.type === type && r.status === statusCode),
  );
}
