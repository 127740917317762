import { CardPaymentChannelPipe } from './../../lib/pipes/card-payment-channel.pipe';
import { NgModule } from '@angular/core';
import { TransactionTypePipe } from '../../lib/pipes/transaction-type.pipe';
import { TransactionStatusPipe } from '../../lib/pipes/transaction-status.pipe';
import { TransferStatusPipe } from '../../lib/pipes/transfer-status.pipe';
import { IrisCardStatusPipe } from '../../lib/pipes/iriscard-status.pipe';
import { UserStatusPipe } from '../../lib/pipes/user-status.pipe';
import { UserNamePipe } from '../../lib/pipes/user-name.pipe';
import { TransactionLimitPipe } from '../../lib/pipes/transaction-limit.pipe';
import { OperationTypePipe } from '../../lib/pipes/operation-type.pipe';
import { NotificationTypePipe } from '../../lib/pipes/notification-type.pipe';
import {
  UserDocumentTypeLongPipe,
  UserDocumentTypePipe,
} from '../../lib/pipes/user-document-type';
import { DepositAccountTypePipe } from '../../lib/pipes/deposit-account-type.pipe';
import { DepositAccountMaskPipe } from '../../lib/pipes/deposit-account-mask.pipe';
import { BatchErrorPipe } from '../../lib/pipes/batch-error.pipe';
import { DepositAccountPipe } from '../../lib/pipes/deposit-account.pipe';
import { IrisCurrencyPipe } from '../../lib/pipes/currency.pipe';
import { LocalDatePipe } from '../../lib/pipes/local-date.pipe';
import { PeriodicityPipe } from '../../lib/pipes/periodicity.pipe';
import { TransferTypePipe } from '../../lib/pipes/transfer-type.pipe';
import { InscribedAccountStatusPipe } from '../../lib/pipes/inscribed-account-status.pipe';
import { OperationPipe } from '../../lib/pipes/operation.pipe';
import { LogChannelPipe } from '../../lib/pipes/log-channel.pipe';
import { LogComponentPipe } from '../../lib/pipes/log-component.pipe';
import { TransactionBatchUpdateStatusPipe } from '../../lib/pipes/transaction-batch-update-status.pipe';
import { TransactionReferencePipe } from '../../lib/pipes/transactionreference.pipe';
import { TransactionMambuStatusPipe } from '../../lib/pipes/transaction-mambu-status.pipe';
import { ArraySortPipe } from '../../lib/pipes/sort.pipe';
import { PlanInformationPipe } from 'src/lib/pipes/plan-information.pipe';
import { MaskAccountPipe } from 'src/lib/pipes/mask-account.pipe';
import { ComplementPipe } from '../../lib/pipes/complement.pipe';
import { TruncatePipe } from 'src/lib/pipes/truncate.pipe';
import { CountryNamePipe } from 'src/lib/pipes/country-name.pipe';
import { PhoneCellMaskPipe } from 'src/lib/pipes/phone-cell.pipe'

const pipes = [
  TransactionTypePipe,
  TransactionStatusPipe,
  TransferStatusPipe,
  IrisCardStatusPipe,
  UserStatusPipe,
  UserNamePipe,
  TransactionLimitPipe,
  OperationTypePipe,
  NotificationTypePipe,
  UserDocumentTypePipe,
  UserDocumentTypeLongPipe,
  DepositAccountTypePipe,
  DepositAccountMaskPipe,
  BatchErrorPipe,
  DepositAccountPipe,
  IrisCurrencyPipe,
  LocalDatePipe,
  PeriodicityPipe,
  TransferTypePipe,
  InscribedAccountStatusPipe,
  OperationPipe,
  LogChannelPipe,
  LogComponentPipe,
  TransactionBatchUpdateStatusPipe,
  TransactionReferencePipe,
  TransactionMambuStatusPipe,
  UserNamePipe,
  ArraySortPipe,
  PlanInformationPipe,
  MaskAccountPipe,
  ComplementPipe,
  TruncatePipe,
  CardPaymentChannelPipe,
  CountryNamePipe,
  PhoneCellMaskPipe
];

@NgModule({
  declarations: pipes,
  imports: [],
  exports: pipes,
})
export class PipesModule {}
