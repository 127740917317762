import { Pipe, PipeTransform } from '@angular/core';
import { TransferType } from '../services/transaction/transaction.types';

@Pipe({
  name: 'transfertype',
})
export class TransferTypePipe implements PipeTransform {
  transform(value: TransferType | string): string {
    if (typeof value === 'string') {
      return `Transactions.ScheduledTransactions.Type.${value}`;
    }
    return `Transactions.ScheduledTransactions.Type.${TransferType[value]}`;
  }
}
