import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import {
  InscribedAccount,
  InscribedAccountFilter, InscribedAccountStatus
} from 'src/lib/models/inscribed-account.model';
import { BaseService } from '../base/base.service';
import { PaginatedResponse } from '../base/pagination.model';
import { TransferType } from '../transaction/transaction.types';

@Injectable()
export class InscribedAccountsService extends BaseService {
  private irisInscribedCache$: Observable<InscribedAccount[]>;
  private otherBanksInscribedCache$: Observable<InscribedAccount[]>;

  create(register: InscribedAccount, otp: OtpViewModel): Observable<string> {
    return this.http
      .post(this.settings.get.registerAccountsApi, register, {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      })
      .pipe(
        tap(() => {
          this.refreshCache(register.accountTransactionType);
        })
      );
  }

  get(type: TransferType): Observable<InscribedAccount[]> {
    switch (type) {
      case TransferType.SAME_BANK:
        return this.getIrisInscribedAccounts();
      case TransferType.OTHER_BANK:
        return this.getOtherBanksInscribedAccounts();
      default:
        return of([]);
    }
  }

  search(
    filters: InscribedAccountFilter
  ): Observable<PaginatedResponse<InscribedAccount>> {
    return this.http.get<PaginatedResponse<InscribedAccount>>(
      `${this.settings.get.registerAccountsApi}/search`,
      {
        params: filters as any,
      }
    );
  }

  edit(
    inscribedAccount: InscribedAccount,
    otp: OtpViewModel
  ): Observable<ArrayBuffer> {
    return this.http.put<ArrayBuffer>(
      `${this.settings.get.registerAccountsApi}/${inscribedAccount.id}`,
      inscribedAccount,
      {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      }
    );
  }

  eliminate(accountId: number, otp: OtpViewModel): Observable<ArrayBuffer> {
    return this.http.delete<ArrayBuffer>(
      `${this.settings.get.registerAccountsApi}/${accountId}`,
      {
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      }
    );
  }

  resetCache(): void {
    this.irisInscribedCache$ = undefined;
    this.otherBanksInscribedCache$ = undefined;
  }

  private getIrisInscribedAccounts(): Observable<InscribedAccount[]> {
    if (!this.irisInscribedCache$) {
      this.irisInscribedCache$ = this.fetchInscribedAccounts(
        TransferType.SAME_BANK
      ).pipe(shareReplay());
    }

    return this.irisInscribedCache$;
  }

  private getOtherBanksInscribedAccounts(): Observable<InscribedAccount[]> {
    if (!this.otherBanksInscribedCache$) {
      this.otherBanksInscribedCache$ = this.fetchInscribedAccounts(
        TransferType.OTHER_BANK
      ).pipe(shareReplay());
    }

    return this.otherBanksInscribedCache$;
  }

  private fetchInscribedAccounts(type: TransferType) {
    return this.http.get<InscribedAccount[]>(
      this.settings.get.registerAccountsApi,
      {
        params: new HttpParams()
          .set('transactionType', TransferType[type])
          .set('status', InscribedAccountStatus[InscribedAccountStatus.ACTIVE]),
      }
    );
  }

  private refreshCache(type: TransferType) {
    switch (type) {
      case TransferType.SAME_BANK:
        this.irisInscribedCache$ = null;
        break;
      case TransferType.OTHER_BANK:
        this.otherBanksInscribedCache$ = null;
        break;
    }
  }
}
