import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FraudMonitoringConstants,
  FraudMonitoringDeviceProperties,
  FraudMonitoringService,
} from '@iris/iris-otp';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {
  constructor(private fraudMonitoringService: FraudMonitoringService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.fraudMonitoringService.getDeviceProperties()).pipe(
      mergeMap((deviceProperties: FraudMonitoringDeviceProperties) => {
        const headers = {};
        headers[FraudMonitoringConstants.DEVICE_TOKEN_HEADER] =
          deviceProperties.deviceTokenCookie;
        headers[FraudMonitoringConstants.DEVICE_PRINT_HEADER] =
          deviceProperties.deviceFootPrint;
        headers['Referrer-Policy'] = 'same-origin';
        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request);
      }),
    );
  }
}
