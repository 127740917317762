import { Pipe, PipeTransform } from '@angular/core';
import { MambuTransactionType } from '../models';

@Pipe({ name: 'transactiontype' })
export class TransactionTypePipe implements PipeTransform {
  transform(value: MambuTransactionType): string {
    if (typeof value === 'string') {
      return `TransactionTypes.${value}`;
    }

    return `TransactionTypes.${MambuTransactionType[value]}`;
  }
}
