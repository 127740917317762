import { Injectable } from '@angular/core';
import { RoleModules,RolePermissions } from '../roles/role.types';
import { AccountService, SessionService } from '@iris/iris-authentication';

@Injectable({
  providedIn: 'root',
})
export class UserHasPermissionService {
  constructor(
    private accountService: AccountService,
    private sessionService: SessionService,
  ) {}

  hasPermission(permissions: string[]): boolean {
    if (!permissions) {
      return true;
    }

    if (!this.sessionService.credentials?.token) {
      window.location.href = '/login?expiredsession=true';
    }

    const myPermissions:RolePermissions[] = this.sessionService.claim()?.permissions || [];

    return myPermissions.find(p => permissions.includes(p)) !== undefined;
  }

  async isAuthorizer(): Promise<boolean> {
    const myRole = await this.accountService.role().toPromise();
    if (!myRole.enabled) {
      return false;
    }
    
    const transactionPermissions = myRole.permissions.filter(
      p => p.module === RoleModules.TRANSACTIONS,
    );

    if (!transactionPermissions.length) {
      return false;
    }

    return transactionPermissions.some(p => !p.options?.needsAuthorization)
    
  }
}
