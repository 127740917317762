import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StatusCodes } from '@iris/iris-base';
import { BatchService } from '../services/batch/batch.service';

@Directive({
  selector: '[validbatchdetails]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: BatchDetailsDirective,
      multi: true,
    },
  ],
})
export class BatchDetailsDirective implements AsyncValidator {
  constructor(
    private batchService: BatchService,
    private changeDetector: ChangeDetectorRef
  ) {}
  @Input('validbatchdetails') editedBatchName: string;

  static readonly MaxLength = 20;

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    if (control.value == this.editedBatchName) return of(null);

    if (control.value?.length > BatchDetailsDirective.MaxLength)
      return of({ maxLengthExceeded: true });

    return timer(750).pipe(
      switchMap(() => this.batchService.nameAlreadyExists(control.value)),
      catchError((e) => {
        if (e.status === StatusCodes.NotFound) {
          this.changeDetector.markForCheck();
          return of(false);
        }

        throw e;
      }),
      map((exists) => {
        this.changeDetector.markForCheck();
        return exists === false ? null : { nameAlreadyExists: true };
      })
    );
  }
}
