import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phonecellmask'})
export class PhoneCellMaskPipe implements PipeTransform {
  transform(phone: string): string {
    if (!phone) {
      return null;
    }
    if (phone.length !== 13) {
      return phone;
    }
    phone = phone.replace('+57', '');
    let match = phone.match(/(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if(match) {
      return `+57 ${ match[1]} ${match[2]} ${match[3]} ${match[4]}`;  
    }
    return null;
  }
}
