import { OrderedRequest } from '../services/base/ordered.model';
import { PaginatedRequest } from '../services/base/pagination.model';
import { TransferType } from '../services/transaction/transaction.types';
import { DepositAccountType } from './depositaccount.model';

export enum InscribedAccountStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REFUSED_ACH = 'REFUSED_ACH',
  NEW = 'NEW',
}

export interface InscribedAccount {
  id?: number;
  accountId: string;
  accountOwner: string;
  accountTransactionType: TransferType;
  accountType: DepositAccountType;
  userDocumentId?: string;
  userDocumentType?: number;
  bankId?: string;
  description?: string;
  status?: InscribedAccountStatus;
  tag?: string;
  accountOwnerEmail?: string;
  accountOwnerNotification?: boolean;
  inscribeAccount?: boolean;
  rejectCode?: string;
  rejectCause?: string;
}

export enum InscribedAccountOrdering {
  ACCOUNT_HOLDER = 'ACCOUNT_HOLDER',
  BANK = 'BANK_ID',
  STATUS = 'STATUS',
  UPDATEDAT = 'UPDATEDAT',
}

export interface InscribedAccountFilter
  extends PaginatedRequest,
    OrderedRequest<InscribedAccountOrdering> {
  destinationAccountIdDescription?: string;
  destinationBankId?: string[];
  destinationAccountOwnerUserAuthorizer?: string;
  destinationOwnerDocumentType?: string[];
}
