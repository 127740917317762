<div id="walktrough">
  <iris-modal [isShowing]="showModal">
    <div class="bg-img d-none d-lg-block"></div>
    <img
      class="close-button"
      [src]="'icons/basic-interface/close.svg' | asset"
      (click)="close()"
    />
    <div class="embracer">
      <swiper [config]="swiperConfig" #plansSwiper class="my-swiper">
        <ng-container *ngFor="let step of steps; let i = index">
          <ng-template swiperSlide class="swiper-slide">
            <div class="d-flex align-items-center justify-content-center h-100">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <img
                    class="img-fluid"
                    [src]="step.image | asset"
                    [alt]="step.image"
                  />
                </div>
                <div class="col-12 col-lg-6">
                  <div
                    class="d-flex align-items-center justify-content-center h-100"
                  >
                    <div class="step">
                      <h1 class="step-title mt-3 mt-lg-0">
                        {{ step.title | translate }}
                      </h1>
                      <div class="underline"></div>
                      <div
                        class="step-desc"
                        [innerHTML]="step.description | translate"
                      ></div>
                      <button
                        *ngIf="i === steps.length - 1"
                        (click)="close()"
                        class="btn btn-outline-primary mt-3"
                      >
                        {{ 'Walktrough.OkButton' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </iris-modal>
</div>
