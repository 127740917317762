import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  IHandleSessionControlService,
  SessionNotificationType,
} from '@iris/iris-authentication';

@Injectable({ providedIn: 'root' })
export class HandleSessionControlService
  implements IHandleSessionControlService
{
  constructor(private location: Location) {}

  action(notificationType: SessionNotificationType): void {
    this.location.back();
  }

  handle(notificationType: SessionNotificationType): void {}
}
