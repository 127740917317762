import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { BaseService } from '../base/base.service';
import { Operation } from '../transaction/transaction.types';
import { map, shareReplay, tap } from 'rxjs/operators';
import { DepositAccountService } from '../depositAccount/depositAccount.service';
import { SettingsService } from '../settings/settings.service';
import { CategoriesService } from './categories.service';
import {Category, Tag, TagFilters, TagType} from "@iris/iris-components";

@Injectable()
export class TagService extends BaseService {
  private tagsCache$: any = {};

  constructor(
    private depositAccountService: DepositAccountService,
    private categoriesService: CategoriesService,
    protected http: HttpClient,
    protected settings: SettingsService,
  ) {
    super(http, settings);
  }

  get(filters?: TagFilters, cached?: boolean): Observable<Tag[]> {
    if (cached || cached === undefined) {
      const key = btoa(JSON.stringify(filters));
      const cache$ = this.tagsCache$[key];

      if (!cache$) {
        this.tagsCache$[key] = this.get(filters, false).pipe(shareReplay());
      }

      return this.tagsCache$[key];
    }

    const tags$ = this.http.get<Tag[]>(this.settings.get.tagApi, {
      params: filters as any,
    });

    return this.mapTags(tags$);
  }

  getById(id: string): Observable<Tag> {
    return this.http.get<Tag>(`${this.settings.get.tagApi}/${id}`);
  }

  create(model: Tag): Observable<string> {
    return this.http
      .post(this.settings.get.tagApi, model, {
        responseType: 'text',
      })
      .pipe(
        tap(() => {
          this.resetCache();
          this.resetDepositAccountCache(model);
        }),
      );
  }

  update(model: Tag): Observable<string> {
    return this.http
      .put(`${this.settings.get.tagApi}/${model.id}`, model, {
        responseType: 'text',
      })
      .pipe(
        tap(() => {
          this.resetCache();
          this.resetDepositAccountCache(model);
        }),
      );
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.settings.get.tagApi}/${id}`);
  }

  getForUsage(operation?: Operation) {
    let params = new HttpParams();
    if (operation !== undefined) {
      params = params.set('operations', operation.toString());
    }

    const tags$ = this.http.get<Tag[]>(`${this.settings.get.tagApi}/forUsage`, {
      params: params,
    });

    return this.mapTags(tags$);
  }

  resetCache(): void {
    this.tagsCache$ = {};
  }

  private resetDepositAccountCache(model: Tag) {
    if (model.type === TagType.DepositAccount) {
      this.depositAccountService.resetCache();
    }
  }

  private mapTags(tags$: Observable<Tag[]>) {
    const categories$ = this.categoriesService.get();

    return zip(tags$, categories$).pipe(
      map(response => {
        const tags = response[0].map(tag => this.mapTag(tag, response[1]));

        return tags;
      }),
      shareReplay(),
    );
  }

  private mapTag(model: Tag, categories: Category[]) {
    let category = categories.find(
      category => category.id === model.categoryId,
    );

    if (!category) {
      category = categories[0];
    }

    model.color = category.color;
    return model;
  }
}
