import { Injectable } from '@angular/core';
import { pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IMapRoleName } from './role.types';
import { RoleService } from './roles.service';

@Injectable()
export class RoleMapperService {
  constructor(private roleService: RoleService) {}

  map(entities: IMapRoleName[]) {
    return this.roleService.get().pipe(
      tap(roles => {
        entities.forEach(e => {
          var role = roles.find(u => u.id == e.roleId);
          if (roles) {
            e.roleName = `${role?.name}`;
          }
        });
      }),
    );
  }
}
