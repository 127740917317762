import { Pipe, PipeTransform } from '@angular/core';
import { Level, PlanInformation } from '../services/plans/plans.types';

@Pipe({ name: 'planinformation' })
export class PlanInformationPipe implements PipeTransform {
  transform(value: PlanInformation) {
    const level = value.level === Level.Error ? 'Error' : 'Warning';
    return {
      title: `Dashboard.Plans.${level}.${value.type}.Title`,
      message: `Dashboard.Plans.${level}.${value.type}.Message`,
    };
  }
}
