import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class WalktroughService extends BaseService {
  constructor(protected http: HttpClient, protected settings: SettingsService) {
    super(http, settings);
  }

  updateWalktrough(completed: string[]): Observable<any> {
    return this.http.patch(
      this.settings.get.accountApi + '/me',

      [
        {
          op: 'REPLACE',
          path: 'requiredWalkthrough',
          value: completed,
        },
      ],
      {
        headers: {
          'content-type': 'application/json-patch+json',
        },
      },
    );
  }
}
