import { Injectable } from '@angular/core';
import { IrisOtpConstants, OtpViewModel } from '@iris/iris-otp';
import { Observable, of, Subject } from 'rxjs';
import { Authorization } from 'src/lib/models';
import { BaseService } from '../base/base.service';
import { PaginatedResponse } from '../base/pagination.model';
import {
  AuthorizationFilter,
  AuthorizationViewModel,
} from './authorizations.types';

@Injectable()
export class AuthorizationsService extends BaseService {
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange() {
    this.emitChangeSource.next(null);
  }

  get(
    filters?: AuthorizationFilter,
  ): Observable<PaginatedResponse<Authorization>> {
    return this.http.get<PaginatedResponse<Authorization>>(
      this.settings.get.authorizationsApi,
      {
        params: filters as any,
      },
    );
  }

  count(filters?: AuthorizationFilter): Observable<number> {
    return this.http.get<number>(
      `${this.settings.get.authorizationsApi}/count`,
      {
        params: filters as any,
      },
    );
  }

  cancel(payload: AuthorizationViewModel[], otp: OtpViewModel) {
    return this.http.put(
      `${this.settings.get.authorizationsApi}/cancel`,
      payload,
      {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  approve(payload: AuthorizationViewModel[], otp: OtpViewModel) {
    return this.http.put(
      `${this.settings.get.authorizationsApi}/approve`,
      payload,
      {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  eliminate(payload: AuthorizationViewModel[], otp: OtpViewModel) {
    return this.http.put(
      `${this.settings.get.authorizationsApi}/reject`,
      payload,
      {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }

  applicationList(filters?: any): Observable<any> {
    return this.http.get(`${this.settings.get.eprepaidApi}/requests`, {
      params: filters as any,
    });
  }

  applicationById(id: string): Observable<any> {
    return this.http.get(`${this.settings.get.eprepaidApi}/requests/${id}`);
  }

  approveOrRejectApplication(
    id: string,
    otp: OtpViewModel,
    type: 'approve' | 'reject',
  ): Observable<any> {
    return this.http.put(
      `${this.settings.get.eprepaidApi}/requests/${id}/${type}`,
      {},
      {
        responseType: 'text',
        headers: {
          [IrisOtpConstants.OtpCodeHeader]: otp.otpCode,
          [IrisOtpConstants.OtpIdHeader]: otp.otpId,
        },
      },
    );
  }
}
